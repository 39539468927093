<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<incident-modals :modelName="modelName"></incident-modals>
	</div>
</template>

<script>
import incidentActions from './IncidentActions';
import incidentModals from './IncidentModals.vue';

export default {
	name: 'incidentgrid',
	components: {
		'incident-modals': incidentModals
	},
	data() {
		return {
			modelName: 'incident',
			actions: incidentActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
